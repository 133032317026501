import { createContext, useContext, useState, ReactNode } from 'react'

interface MenuContextType {
    isDashboardDrawerOpened: boolean
    openedItem: string
    openedComponent: string
    openedHorizontalItem: string | null
    isComponentDrawerOpened: boolean
    toggleDrawer: () => void
    setOpenedItem: (item: string) => void
}

const MenuContext = createContext<MenuContextType | undefined>(undefined)

export function MenuProvider({ children }: { children: ReactNode }) {
    const [state, setState] = useState({
        openedItem: 'dashboard',
        openedComponent: 'buttons',
        openedHorizontalItem: null,
        isDashboardDrawerOpened: false,
        isComponentDrawerOpened: true,
    })

    const toggleDrawer = () => {
        setState((prev) => ({
            ...prev,
            isDashboardDrawerOpened: !prev.isDashboardDrawerOpened,
        }))
    }

    const setOpenedItem = (openedItem: string) => {
        setState((prev) => ({
            ...prev,
            openedItem,
        }))
    }

    return <MenuContext.Provider value={{ ...state, toggleDrawer, setOpenedItem }}>{children}</MenuContext.Provider>
}

export function useMenu() {
    const context = useContext(MenuContext)
    if (context === undefined) {
        throw new Error('useMenu must be used within a MenuProvider')
    }
    return context
}
