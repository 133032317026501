// material-ui
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import Box from '@mui/material/Box'

// assets
import SearchOutlined from '@ant-design/icons/SearchOutlined'

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

export default function Search() {
    return (
        <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
            <FormControl sx={{ width: { xs: '100%', md: 224 } }}>
                <OutlinedInput
                    size="small"
                    id="header-search"
                    startAdornment={
                        <InputAdornment position="start" sx={{ mr: -0.5 }}>
                            <SearchOutlined />
                        </InputAdornment>
                    }
                    aria-describedby="header-search-text"
                    inputProps={{
                        'aria-label': 'weight',
                    }}
                    placeholder="Search"
                />
            </FormControl>
        </Box>
    )
}
