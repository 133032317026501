import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'

export class ApiClientService {
    private axiosInstance: AxiosInstance

    constructor(baseURL: string) {
        // Create axios instance
        this.axiosInstance = axios.create({
            baseURL,
            headers: {
                'Content-Type': 'application/json',
                'X-App-Version': import.meta.env.VITE_APP_VERSION,
            },
        })

        // Add token to request headers
        this.axiosInstance.interceptors.request.use((config) => {
            const token = localStorage.getItem(import.meta.env.VITE_TOKEN_STORAGE_KEY)
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
            return config
        })

        // Refresh token on response, if the header is present
        this.axiosInstance.interceptors.response.use((response) => {
            if (response.headers['x-refresh-token']) {
                localStorage.setItem(import.meta.env.VITE_TOKEN_STORAGE_KEY, response.headers['x-refresh-token'])
            }
            return response
        })
    }

    public async get<T>(url: string, config?: InternalAxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.axiosInstance.get(url, config)
        return response.data
    }

    public async post<T>(url: string, data?: unknown, config?: InternalAxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.axiosInstance.post(url, data, config)
        return response.data
    }

    public async put<T>(url: string, data?: unknown, config?: InternalAxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.axiosInstance.put(url, data, config)
        return response.data
    }

    public async patch<T>(url: string, data?: unknown, config?: InternalAxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.axiosInstance.patch(url, data, config)
        return response.data
    }

    public async delete<T>(url: string, config?: InternalAxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.axiosInstance.delete(url, config)
        return response.data
    }
}

export const apiV1Client = new ApiClientService(import.meta.env.VITE_API_V1_BASE_URL)
