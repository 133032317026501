/* eslint-disable @typescript-eslint/no-explicit-any */

import { ComponentType, lazy, Suspense } from 'react'
import Loader from './Loader'

const Loadable = (importFunc: () => Promise<{ default: ComponentType<any> }>) => {
    const LazyComponent = lazy(importFunc)

    return (props: any) => (
        <Suspense fallback={<Loader />}>
            <LazyComponent {...props} />
        </Suspense>
    )
}

export default Loadable
