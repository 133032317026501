import React from 'react'
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar'

type AvatarSize = 'small' | 'medium' | 'large'

interface AvatarProps extends Omit<MuiAvatarProps, 'size'> {
    size?: AvatarSize
}

const sizeMap = {
    small: 24,
    medium: 32,
    large: 40,
}

const Avatar: React.FC<AvatarProps> = ({ size = 'medium', sx, ...props }) => {
    const dimension = sizeMap[size]

    return (
        <MuiAvatar
            sx={{
                width: dimension,
                height: dimension,
                ...sx,
            }}
            {...props}
        />
    )
}

export default Avatar
