// project import
import dashboard from './dashboard'
import pages from './page'
import utilities from './utilities'
import support from './support'
import { NavigationItem } from '@/interfaces/GlobalInterfaces.ts'

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavigationItem[] } = {
    items: [dashboard, utilities, pages, support],
}

export default menuItems
