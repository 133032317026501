import { MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// material-ui
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

// assets
import {
    CommentOutlined,
    LockOutlined,
    QuestionCircleOutlined,
    UnorderedListOutlined,
    UserOutlined,
} from '@ant-design/icons'

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

export default function SettingTab() {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const navigate = useNavigate()

    const handleListItemClick = (_event: MouseEvent<HTMLDivElement>, index: number, link: string) => {
        setSelectedIndex(index)
        navigate(link)
    }

    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
            <ListItemButton
                selected={selectedIndex === 0}
                onClick={(event: MouseEvent<HTMLDivElement>) =>
                    handleListItemClick(event, 0, '/apps/profiles/user/personal')
                }
            >
                <ListItemIcon>
                    <QuestionCircleOutlined />
                </ListItemIcon>
                <ListItemText primary="Support" />
            </ListItemButton>
            <ListItemButton
                selected={selectedIndex === 1}
                onClick={(event: MouseEvent<HTMLDivElement>) =>
                    handleListItemClick(event, 1, '/apps/profiles/account/basic')
                }
            >
                <ListItemIcon>
                    <UserOutlined />
                </ListItemIcon>
                <ListItemText primary="Account Settings" />
            </ListItemButton>
            <ListItemButton
                selected={selectedIndex === 2}
                onClick={(event: MouseEvent<HTMLDivElement>) =>
                    handleListItemClick(event, 2, '/apps/profiles/account/personal')
                }
            >
                <ListItemIcon>
                    <LockOutlined />
                </ListItemIcon>
                <ListItemText primary="Privacy Center" />
            </ListItemButton>
            <ListItemButton
                selected={selectedIndex === 3}
                onClick={(event: MouseEvent<HTMLDivElement>) =>
                    handleListItemClick(event, 3, '/apps/profiles/account/personal')
                }
            >
                <ListItemIcon>
                    <CommentOutlined />
                </ListItemIcon>
                <ListItemText primary="Feedback" />
            </ListItemButton>
            <ListItemButton
                selected={selectedIndex === 4}
                onClick={(event: MouseEvent<HTMLDivElement>) =>
                    handleListItemClick(event, 4, '/apps/profiles/account/personal')
                }
            >
                <ListItemIcon>
                    <UnorderedListOutlined />
                </ListItemIcon>
                <ListItemText primary="History" />
            </ListItemButton>
        </List>
    )
}
